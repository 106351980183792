<template>
  <div class="content">
    <navbar :show-navbar="showMenu">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{toggled: showMenu}">
          <navbar-toggle-button @click.native="toggleNavbar">
          </navbar-toggle-button>
        </div>
        <router-link :to="{ path: '/'}" tag="a" class="navbar-brand">
          <img src="@/assets/img/logos/natca-logo-white-bg.png" style="width: 140px; margin-top:-20px" alt="">

        </router-link>
      </div>

      <template slot="navbar-menu">
        <span class="mr-5">
          <facility-dropdown class="mr-5"></facility-dropdown>
        </span>

        <template v-if="facility">
          <li v-if="!loggedIn || !facility.id" class="nav-item">
            <a class="nav-link" @click.prevent="$auth.loginWithRedirect()" href="#">
              <i class="fas fa-user"></i> Login
            </a>
          </li>
          <router-link v-else class="nav-item" tag="li" to="/" @click.native="$auth.logout()">
            <a class="nav-link">
              <i class="fas fa-user"></i>
              Logout
            </a>
          </router-link>
        </template>
      </template>
    </navbar>

    <router-view name="header"></router-view>

    <div class="wrapper wrapper-full-page">
      <div class="content">

        <div class="container">
          <alerts></alerts>

          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view></router-view>
<!--            <router-view @login="$auth.login()"></router-view>-->
          </zoom-center-transition>
        </div>

        <content-footer></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ZoomCenterTransition } from 'vue2-transitions'
import ContentFooter from '../Layout/_Footer.vue'
import { Navbar, NavbarToggleButton } from '@/components/stateless'
import { Alerts, FacilityDropdown } from '@/components'

export default {
  name: 'layout-home',
  components: {
    ContentFooter,
    FacilityDropdown,
    ZoomCenterTransition,
    Navbar,
    NavbarToggleButton,
    Alerts
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    },
    alert: Object
  },
  data () {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear()
    }
  },
  created () {
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      loggedIn: 'auth/loggedIn',
      member: 'member/member'
    })
  },
  methods: {
    toggleNavbar () {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu () {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    }
  },
  beforeDestroy () {
    this.closeMenu()
  },
  watch: {
  }
}
</script>
<style lang="scss">
    .content {
        // padding-top: 70px !important;
    }
    $scaleSize: .8;
    @keyframes zoomIn8 {
        from {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }
        100% {
            opacity: 1;
        }
    }

    .wrapper-full-page .zoomIn {
        animation-name: zoomIn8;
    }

    @keyframes zoomOut8 {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }
    }

    .wrapper-full-page .zoomOut {
        animation-name: zoomOut8;
    }
</style>
